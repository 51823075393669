.page-header {

    $this: &;

    @include until(l) {
        &.js-menu-open {
            *:not(:hover) {
                color: $colorGreenText;
            }
            .btn {
                background-color: $colorYellowLight;
                &:hover {
                    background-color: $colorGreenMedium;
                }
                &.is-active {
                    background-color: $colorGreen;
                }
            }
        }
    }

    &__menu {

        &-arrow {

            width: 40px;
            height: 27px;

            @include from(sp) {
                width: 60px;
                height: 40px;
            }

            @include from(m) {
                width: 80px;
                height: 55px;
            }
        }

        &-anchors &-arrow {
            transform: rotate(90deg);
            @include from(sp) {
                margin-top: 60px;
            }
        }

        @include until(l) {
            @include scrollable();
        }
        @include from(l) {
            .u-wrap {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    &__burger {
        &-gfx {
            width: 20px;
            height: 14px;
            > span {
                height: 2px;
                transition: transform 0.3s 0.15s;
                &:first-child {
                    top: 0;
                }
                &:nth-child(2) {
                    top: 6px;
                }
                &:last-child {
                    top: 12px;
                }
                > span {
                    transition: transform 0.3s;
                    [aria-expanded="true"] & {
                        transition: transform 0.3s 0.15s;
                    }
                }
                [aria-expanded="true"] & {
                    transition: transform 0.3s 0s;
                    &:first-child {
                        transform: translate(0, 6px);
                        > span {
                            transform: rotate(45deg);
                        }
                    }
                    &:nth-child(2) > span {
                        transform: scale(0);
                    }
                    &:last-child {
                        transform: translate(0, -6px);
                        > span {
                            transform: rotate(-45deg);
                        }
                    }
                }
            }
        }
    }

    &__wrap {
        transition: transform 0.3s $easeOutCubic;
        #{$this}.is-hidden & {
            transform: translate(0, -100%);
            transition: transform 0.5s $easeInOutCubic;
        }
        #{$this}.js-menu-open & {
            transform: none !important;
        }
    }

    &__bar {
        transition: background-color 0.3s;
        #{$this}.is-transparent & {
            background-color: transparent;
        }
    }
}
