.image {
  &.is-portrait {
    max-width: calc(50vh * (9/16));
    @supports (--custom:property) {
      max-width: calc((var(--vh, 1vh) * 50) * var(--ratio, (9/16)));
    }
  }
  &.is-landscape {
    max-width: calc(65vh * (16/9));
    @supports (--custom:property) {
      max-width: calc((var(--vh, 1vh) * 65) * var(--ratio, (16/9)));
    }
  }
}