/* ==============================
    Basic/generic global styles
 ============================== */

body {
    min-height: calc(100% + 1px);
}

.lazyload,
.lazyloading {
    opacity: 0;
    transform: translateZ(0);
}

.lazyloaded {
    opacity: 1;
    transition: opacity 0.3s $easeOutQuad;
}

.objectfit .lazyload.lazyzoom,
.objectfit .lazyloading.lazyzoom {
    transform: scale(1.03, 1.03) translate(0, 1vh);
    transform-origin: center center;
}

.objectfit .lazyloaded.lazyzoom {
    transform: scale(1, 1) translate(0, 0);
    transform-origin: center bottom;
    transition: opacity 0.5s $easeInQuad, transform 1s $easeOutQuad;
}

.no-js {
    img.lazyload,
    img.lzld {
        display: none;
    }
}

[hidden]:not(:target) {
    display: none !important;
}

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear,
input[type=search]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

button, a {
    touch-action: manipulation;
}

html.no-outline * {
    outline: none;
}

::-moz-selection {
    background: $colorText;
    color: $colorBackground;
}

::selection {
    background: rgba($colorText, 0.99);
    color: $colorBackground;
}

table {
    th, td {
        vertical-align: top;
        font-weight: normal;
        text-align: left;
    }
}

.clickable {
    &:active {
        transform: scale(0.9);
    }
}

.link {
    text-decoration: underline;
    text-decoration-color: currentColor;
    transition: text-decoration-color 0.3s;
    &:hover,
    a:hover &,
    button:hover & {
        text-decoration-color: transparent;
    }
}

strong, b {
    font-weight: 500;
}

em, i {
    font-style: italic;
}

.mask-1 {
    mask-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjgwIiBoZWlnaHQ9IjY4MCIgdmlld0JveD0iMCAwIDY4MCA2ODAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTY0My4zMyAxNzYuNzc0YzQ5LjI0MiAxNDAuMzIgNjQuNTM0IDMzNy4yNTQtNTUuMjE2IDQxOC4yOC0xMTkuNzQ5IDgxLjAyNy0zNjAuMzUzIDEwMi45ODQtNDk4LjYxOC00Ljg4OHMtOTUuMDg4LTQwMC45MjUtMTIuODItNDgzLjljODIuMjY4LTgyLjk3NSAyNDUuMDk0LTc1LjU0NCAzMjMuMzUtODYuOTlDNDc4LjI4MyA3LjgzIDU5NC4wOSAzNi40NTQgNjQzLjMzIDE3Ni43NzR6IiAvPjwvc3ZnPgo=");
    mask-repeat: no-repeat;
    mask-size: cover;
}

.mask-2 {
    mask-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjgwIiBoZWlnaHQ9IjY4MCIgdmlld0JveD0iMCAwIDY4MCA2ODAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTUzNC45NjYgMTguMzY5YzE1NC42NTcgMzUuNzUzIDE2MC44MTggMjMxLjMgMTMwLjc1NSA0MDMuNTAxLTMwLjA2MyAxNzIuMjAyLTEzNy41OTggMjI3LjM0My0yMzAuMDkgMjQ3LjItOTIuNDkgMTkuODU2LTIwMC4yNjUtMjkuNDE0LTM1Ny4zNzgtMTUyLjM5OHMtMzguOTQtMzc0LjQyNCAyMC42MDUtNDMwLjQyQzE1OC40MDIgMzAuMjU2IDM4MC4zMDctMTcuMzgzIDUzNC45NjYgMTguMzd6IiAvPjwvc3ZnPgo=");
    mask-repeat: no-repeat;
    mask-size: cover;
}

.g-wrap {
    width: 100%;
}

//html.js:not(.js-error):not(.is-live-preview) [data-reveal]:not(.js-reveal) {
//    visibility: hidden;
//    pointer-events: none;
//}
