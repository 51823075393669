// Missing something? It's probably been converted to a custom Tailwind utility
// Check ./src/css/tailwind/utilities

// There's also a chance the utility class you're looking for has been removed, because its use case is easily covered by default utilities in Tailwind
// https://tailwindcss.com/docs

.u {
    &-wrap {
        width: 100%;
        max-width: maxWidth() + (outerMargin()*2);
        padding-left: outerMargin();
        padding-right: outerMargin();
        margin-left: auto;
        margin-right: auto;

        @each $breakpoint in map-keys($breakpoints) {
            @include from($breakpoint) {
                padding-left: outerMargin($breakpoint);
                padding-right: outerMargin($breakpoint);
                max-width: maxWidth($breakpoint) + (outerMargin($breakpoint)*2);
            }
        }
    }

    &-hit {
        position: relative;
        &::before {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            min-width: 44px;
            min-height: 44px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}
