.embed {

    @supports (--custom:property) {
        max-width: calc((var(--vh, 1vh) * 80) * var(--ratio, (16/9)));
    }

    &__btn {
        width: 80px;
        height: 80px;
        @include from(m) {
            width: 120px;
            height: 120px;
        }
        @media (hover: hover) {
            span {
                transition: transform 0.3s;
            }
            a:hover & {
                span {
                    transform: scale(1.2, 1.2);
                }
            }
        }
    }

    iframe {
        position: absolute;
        top: 0; left: 0;
        width: 100%; height: 100%;
    }
}
