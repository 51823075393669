/* =================
    Flickity reset styles
    https://flickity.metafizzy.co
================== */

.flickity-enabled {
    position: relative;
}

.flickity-enabled:focus {
    outline: none;
}

.flickity-viewport {
    //overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
}

.flickity-slider {
    display: flex;
    position: relative;//absolute;
    width: 100%;
    height: 100%;
    > * {
        position: relative !important;
        left: 0 !important;
    }
}

/* draggable */

.flickity-enabled.is-draggable {
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

/* ---- flickity-button ---- */

/*
//!*
//.flickity-button {
//    position: absolute;
//    border: none;
//    color: #333;
//    background: transparent;
//
//    display: none;
//
//    @include from(l) {
//        display: block;
//    }
//}
//
//.flickity-button:hover {
//    cursor: pointer;
//}
//
//.flickity-button:focus {
//    outline: none;
//    //box-shadow: 0 0 0 5px #19F;
//}
//
//.flickity-button:active {
//    opacity: 0.6;
//}
//
//.flickity-button:disabled {
//    opacity: 0.3;
//    cursor: auto;
//    !* prevent disabled button from capturing pointer up event. #716 *!
//    pointer-events: none;
//}
//
//.flickity-button-icon {
//    fill: #333;
//}
//
//!* ---- previous/next buttons ---- *!
//
//.flickity-prev-next-button {
//    top: 50%;
//    width: 24px;
//    height: 24px;
//    !* vertically center *!
//    transform: translateY(-50%);
//}
//
//.flickity-prev-next-button.previous {
//    left: 20px;
//
//    @include from(lp) {
//        left: 80px;
//    }
//}
//
//.flickity-prev-next-button.next {
//    right: 20px;
//
//    @include from(lp) {
//        right: 80px;
//    }
//}
//
//.flickity-prev-next-button .flickity-button-icon {
//    position: absolute;
//    left: 20%;
//    top: 20%;
//    width: 60%;
//    height: 60%;
//}
//
//!* ---- page dots ---- *!
//
//.flickity-page-dots {
//    position: absolute;
//    width: 100%;
//    bottom: -40px;
//    left: 0;
//    padding: 0;
//    margin: 0;
//    list-style: none;
//    text-align: center;
//    line-height: 1;
//
//    @include from(m) {
//        bottom: -60px;
//    }
//}
//
//.flickity-rtl .flickity-page-dots {
//    direction: rtl;
//}
//
//.flickity-page-dots .dot {
//    display: inline-block;
//    width: 8px;
//    height: 8px;
//    margin: 0 2px;
//    background: transparent;
//    border: 1px solid currentColor;
//    border-radius: 50%;
//    cursor: pointer;
//}
//
//.flickity-page-dots .dot.is-selected {
//    background-color: currentColor;
//}
/!*!
*/
