.blog {
    &__date {
        &-blob {
            width: 80px;
            height: 80px;
            @include from(m) {
                width: 120px;
                height: 120px;
            }
        }
        &-line {
            right: 60px;
            top: 160px;
            height: calc(100% - 160px);
        }
    }
}
