.btn {
    height: 40px;
    &--round {
        width: 40px;
    }
    &.is-active {
        background-color: $colorGreen;
        color: $colorGreenText;
    }
}
