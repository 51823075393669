.species {

    max-width: 1180px;

    &__related {
        width: calc(100% + 20px);
        margin-left: -10px;

        @include from(m) {
            width: calc(100% + 60px);
            margin-left: -30px;
        }

        &-item {
            @include from(s) {
                width: 100%;
                max-width: 210px;
            }
        }

    }
}
