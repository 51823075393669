.hotspot {

    $this: &;

    &[aria-expanded="true"] {
        z-index: 100;
    }

    &__icon {

        width: 48px;
        height: 48px;

        @include from(m) {
            width: 60px;
            height: 60px;
        }

        @include from(l) {
            width: 80px;
            height: 80px;

            #{$this}--small & {
                width: 60px;
                height: 60px;
            }
        }
    }

    &__tip {

        width: auto;
        max-width: 90vw;
        transition: opacity 0.3s 0.15s;

        @include from(m) {
            width: 240px;
        }

        > * {
            transform: translate(0, 5px);
            transform-origin: center bottom;
            transition: transform 0.3s 0.15s;
        }

        &-arrow {
            border-width: 15px 15px 0 15px;
            border-left-color: transparent;
            border-right-color: transparent;
            border-bottom-color: transparent;
            @include from(m) {
                border-width: 20px 20px 0 20px;
            }
        }
    }

    html.outline &:focus &__tip,
    &:hover &__tip {
        transition: opacity 0.3s 0s;
        opacity: 1;

        > * {
            transition: transform 0.3s 0s;
            transform: translate(0, 0);
        }
    }

}
