/**
========================
Custom Tailwind components
https://tailwindcss.com/docs/extracting-components
========================
 */

@layer components {

    .h1 {
        @apply text-25 m:text-50 font-semibold;
    }

    .h1-large {
        @apply text-25 m:text-50 l:text-60 font-semibold;
    }

    .h2 {
        @apply text-25 m:text-40 font-semibold;
    }

    .body {
        @apply text-15 m:text-20;
    }

    .pad-shape {
        padding-top: (110 / 1640) * 100vw;
        padding-bottom: (110 / 1640) * 100vw;
        @supports (padding-top: clamp(80px, 100px, 150px)) {
            padding-top: #{'clamp(80px, (110 / 1640) * 100vw, 150px)'};
            padding-bottom: #{'clamp(80px, (110 / 1640) * 100vw, 150px)'};
        }
    }

    .content-section {
        scroll-margin-top: 180px;
        &.pad-shape:not(.pt-0) {
            scroll-margin-top: 60px;
        }
    }

    .content-block {
        @apply w-full mb-70 m:mb-120 l:mb-180 clearfix;
    }

}
