.link-arrow {
    $this: &;
    &::before {
        content: "";
        display: block;
        width: 28px;
        height: 2px;
        background-color: currentColor;
        position: absolute;
        right: 2px; top: 50%;
        transform: translate(0, -50%);
        transform-origin: right center;
        transition: transform 0.3s;
    }
    @media (hover: hover) {
        transition: transform 0.3s;
        a:hover &,
        a:active &,
        button:hover &,
        button:active & {
            &.move {
                transform: translate(28px, 0);
            }
            &::before {
                transform: translate(0, -50%) scale(2, 1);
            }
        }
    }
}
