.bug {

  $this: &;
  min-height: calc(150vh + 200px);

  @include from(l) {
    width: 60%;
    &--center {
      left: 50%;
      transform: translate(-50%, 0);
    }
    &--right {
      left: 50%;
    }
    &--left {
      left: -10%;
    }
  }

  svg {
    min-height: 150vh;
    @supports (--custom:property) {
      min-height: calc(var(--vh, 1vh) * 150);
    }
  }

  &__wrap {
    @include until(l) {
      width: 150%;
      left: -25%;
    }
  }

  &__lottie {

    width: 100px;
    height: 100px;

    @include from(m) {
      width: 140px;
      height: 140px;
    }

    #{$this}--ant &,
    #{$this}--ladybug &,
    #{$this}--spider & {
      width: 80px;
      height: 80px;

      @include from(m) {
        width: 120px;
        height: 120px;
      }

    }

    #{$this}--dragonfly &,
    #{$this}--butterfly &,
    #{$this}--moth &,
    #{$this}--hedgehog &,
    #{$this}--bird &,
    #{$this}--swallow & {
      width: 120px;
      height: 120px;

      @include from(m) {
        width: 160px;
        height: 160px;
      }

      @include from(l) {
        width: 200px;
        height: 200px;
      }
    }

    &-gfx {
      transform: rotate(90deg);

      #{$this}--bee & {
        transform: rotate(-270deg);
      }
    }
  }
}
