.blurb {
    &--blob {
        max-width: 300px;
        &.g-col {
            max-width: 300px + gutter();
            @include from(m) {
                max-width: 375px + gutter(m);
            }
            @include from(l) {
                max-width: 375px + gutter(l);
            }
        }
    }
    &--card {
        @include until(m) {
            max-width: 300px;
        }
        @include from(m) {
            min-width: 480px;
            min-height: 480px;
        }
    }
}
