.slider {
    &__caption {
        html.js & {
            opacity: 0;
            transition: opacity 0.1s;
        }
        html.js .is-selected & {
            opacity: 1;
            transition: opacity 0.3s 0.5s $easeInSine;
        }
    }
}
