.wysiwyg {

    > * {
        margin-bottom: (19/16) * 1em;
    }

    *:last-child {
        margin-bottom: 0;
    }

    *:first-child {
        margin-top: 0;
    }

    a {
        text-decoration: underline;
        text-decoration-color: currentColor;
        transition: text-decoration-color 0.3s;
        &:hover,
        a:hover &,
        button:hover & {
            text-decoration-color: transparent;
        }
    }

    h2, h3, h4, h5, h6 {
        font-weight: 500;
        font-style: normal;
        margin-top: 1.5em;
        margin-bottom: 0.5em;
    }

    strong {
        font-weight: 500;
    }

    em, i {
        font-style: italic;
    }

    // Lists
    ul, ol {
        margin-bottom: (19/16) * 1em;
    }

    li {
        padding-left: 1.15em;
        position: relative;
        margin-bottom: (19/16) * 0.1em;

        &::before {
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    ul li::before {
        content: "•";
    }

    ol {
        counter-reset: list;
    }

    ol li {
        counter-increment: list;
    }

    ol li::before {
        content: counter(list) ".";
    }

    ol ol li::before {
        content: counter(list, lower-alpha) ".";
    }

    li ul,
    li ol {
        padding-top: (19/16) * 0.2em;
        padding-bottom: (19/16) * 0.2em;
    }

}
