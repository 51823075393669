.hero {

    &__text {

        @include from(l) {
            max-width: 540px;

            html.js & {
                opacity: 0;
                transform: translateY(10%);
            }

            body.js-init & {
                opacity: 1;
                transform: none;
                transition: opacity 0.5s 0.1s $easeInOutQuad, transform 0.75s 0.1s $easeOutQuint;
            }
        }
    }

    &__gfx {
        @supports (--custom:property) {
            max-width: calc(70vh * var(--ratio, (4/3)));
        }
        @include from(m) {
            html.js & img {
                display: none;
            }
        }
    }

    &__lottie {
        width: 130%;
        height: 130%;
        margin-top: 3%;
    }

    &__ddalink {
        width: 140px;
        max-width: 100%;
        @include from(m) {
            width: 180px;
        }
    }

}
