/*
==========================
Fonts
** Remember to configure font families for Tailwind in src/tailwind.config.js
==========================
 */
$fontSizeBase: 16px;
$lineHeightBase: 1.5;
$fontDefault: 'Graphik', 'Helvetica Neue', Helvetica, Arial, sans-serif;

/*
==========================
Colors
** Remember to configure colors for Tailwind in src/tailwind.config.js
==========================
 */
$colorBlack: black;
$colorWhite: #FFFFFF;
$colorGreen: #C1C9A0;
$colorGreenText: #193311;
$colorGreenDark: #204116;
$colorGreenMedium: #DDE3C3;
$colorGreenLight: #ECEDD4;
$colorYellow: #FFF200;
$colorYellowLight: #FFFFF2;

$colorText: $colorGreenText;
$colorBackground: $colorYellowLight;

/*
==========================
Breakpoints
** Remember to configure breakpoints for Tailwind in tailwind.config.js!**
==========================
 */

$small: 320px;
$smallPlus: 520px;
$medium: 750px;
$mediumPlus: 980px;
$large: 1200px;
$largePlus: 1420px;
//$xlarge: 1800px;

$breakpoints: (
    s: $small,
    sp: $smallPlus,
    m: $medium,
    mp: $mediumPlus,
    l: $large,
    lp: $largePlus,
    //xl: $xlarge,
);

/*
==========================
Grid settings
==========================
 */

$gutterMap: (
    min: 10px,
    s: 10px,
    sp: 10px,
    m: 24px,
    mp: 24px,
    l: 60px,
    lp: 60px,
    //xl: 60px
);

$outerMarginsMap: (
    min: 20px,
    s: 20px,
    sp: 20px,
    m: 42px,
    mp: 42px,
    l: 100px,
    lp: 100px,
    //xl: 80px
);

$maxContentWidth: 1680px;

// If different max widths are needed, comment this in and comment the variable above out.
//
// $maxContentWidth: (
//     min: 1280px,
//     s: 1280px,
//     sp: 1280px,
//     m: 1280px,
//     mp: 1280px,
//     l: 1280px,
//     lp: 1440px,
//     xl: 1600px
// );

$grid-breakpointMap: $breakpoints;
$grid-breakpoints: (s, sp, m, mp, l, lp);//(s, sp, m, mp, l, lp, xl);
$grid-columns: 12;
$grid-outer-margins: $outerMarginsMap;
$grid-gutters: $gutterMap;
$grid-flexbox: true;
$grid-pull-columns: 6;
$grid-push-columns: 6;
$grid-use-long-classnames: false;
$grid-use-em: false;
$grid-max-width: $maxContentWidth; //Should include support for multiple max-widths at different breakpoings. See NAF Digital
//$grid-max-width-map: $maxContentWidth; // Optional. Use if different max widths are needed at different breakpoints
